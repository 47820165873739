@import './variables';
/* You can add global styles to this file, and also import other style files */

:root {
  color-sheme: light dark;
  --bg-color: #{$color-white};
  --text-color: #{$color-black};
  --border-color: #{$color-mischka};
  --input-color-error: #{$color-burnt-sienna};
  --input-color-disabled: #{$color-manatee};
}

body {
  margin: 0;
  background: var(--bg-color);
  color: var(--text-color);
  font-family: 'SF Pro Text', sans-serif;
  touch-action: manipulation;
}

.about-own-id {
  margin-bottom: 4vh;
}

.own-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.own-big-icon {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.ios {
  .own-big-icon {
    width: 140px;
    height: 140px;
  }

  .about-own-id {
    margin-bottom: 28px;
  }
}

.own-list {

  ion-note {
    &.md {
      font-size: 16px;
      padding-top: 15px;
    }
  }

  ion-item {
    &:first-child::before {
      content: '';
      display: block;
      height: 0;
      border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    }

    &.ios {
      &:first-child::before {
        margin-left: 20px;
        border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));;
      }

        &.ion-invalid {
        &.ion-touched,
        &.ion-dirty {
          --border-color: var(--highlight-background);
        }
      }

      &.item-has-focus {
        --border-color: var(--highlight-color-focused);
      }
    }
  }
}

.or {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666;

  margin: 24px 0;

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    background: #666;
    height: 1px;
    width: calc(50% - 24px);

    bottom: 11px
  }

  &:after {
    right: 0;
  }
}
